import jwtDecode from "jwt-decode";
var config = require("./../config");
var Utils = require("./../helpers/Utils");
/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */
const fetchJSON = (url, options = {}) => {
  const authData = Utils.authData();

  if (authData.tokens) {
    options.body.tokens = JSON.stringify(authData.tokens);
    options.body.username = JSON.stringify(authData.username);

    options.body = JSON.stringify(options.body);
  }
  options.headers["Authorization"] = Utils.encString(options).hash;

  return fetch(config.apibase + url, options)
    .then((response) => {
      if (!response.status === 200) {
        throw response.json();
      }
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
  const user = getLoggedInUser();
  if (!user) {
    return false;
  }
  const decoded = jwtDecode(user.id_token);
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    console.warn("access token expired");
    return false;
  } else {
    return true;
  }
};

/**
 * Checks if user is authenticated
 */
const getRoutes = () => {
  const user = getLoggedInUser();
  if (!user) {
    return [];
  }
  const decoded = jwtDecode(user.id_token);
  const currentTime = Date.now() / 1000;
  if (decoded.exp > currentTime) {
    console.warn("access token expired");
    var role = user.roles ? user.roles.join(",") : null;
    const options = {
      body: JSON.stringify({
        role: role,
      }),
      method: "POST",
      headers: { "Content-Type": "application/json" },
    };
    try {
      const responseData = fetchJSON("/users/routes", options);
      responseData
        .then((response) => {
          if (response.code === 200) {
            const data = response.data ? response.data : [];
            return data;
          } else {
            return [];
          }
        })
        .catch((error) => {
          console.log("Get roles error", error);
          return [];
        });
    } catch (error) {
      console.log("Get roles error", error);
      return [];
    }
  } else {
    return [];
  }
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
  const user = window.localStorage.getItem("user");
  return user ? (typeof user == "object" ? user : JSON.parse(user)) : null;
};

/**
 * Sets the session
 * @param {*} user
 */
const setSession = (user) => {
  // console.log("date expiry", user);
  if (user) window.localStorage.setItem("user", JSON.stringify(user));
  else window.localStorage.removeItem("user");
};

/**
 * Returns the logged in user
 */
const getUserData = () => {
  const user = window.localStorage.getItem("user");
  const userData = user
    ? typeof user == "object"
      ? user
      : JSON.parse(user)
    : null;
  let userTokensData = {};
  if (userData) {
    userTokensData = {
      tokens: {
        id_token: userData.id_token ? userData.id_token : null,
        access_token: userData.access_token ? userData.access_token : null,
        refresh_token: userData.refresh_token ? userData.refresh_token : null,
      },
      username: userData.username ? userData.username : null,
      userId: userData.userId ? userData.userId : null,
      roles: userData.roles ? userData.roles : null,
    };
  }

  return userTokensData;
};

export {
  isUserAuthenticated,
  getLoggedInUser,
  getUserData,
  setSession,
  getRoutes,
  fetchJSON,
};
