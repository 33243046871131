/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILED = "LOGIN_USER_FAILED";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAILED = "REGISTER_USER_FAILED";
export const VERIFY_USER = "VERIFY_USER";
export const VERIFY_USER_SUCCESS = "VERIFY_USER_SUCCESS";
export const VERIFY_USER_FAILED = "VERIFY_USER_FAILED";
export const LOGOUT_USER = "LOGOUT_USER";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_FAILED = "FORGET_PASSWORD_FAILED";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";
/* AUTH UTILS */
export const TOKEN_REFRESH = "TOKEN_REFRESH";
export const TOKEN_REFRESH_RECEIVED = "TOKEN_REFRESH_RECEIVED";
export const TOKEN_REFRESH_FAILED = "TOKEN_REFRESH_FAILED";
/* Pending Dashboard Actions */
export const CREATE_ORDER = "Create Order";
export const CANCEL_ORDER = "Cancel Order";
export const MAP_POSTBACK = "Map Postback";
export const CREATE_CLICK = "Create Click";
export const SKIP_ORDER = "Skip For Now";
export const RESET_ORDER = "Reset Status";
export const PROCESS_POSTBACK = "Process Postback";
