// @flow
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILED,
  VERIFY_USER,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_FAILED,
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAILED,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
} from "../../constants/actionTypes";

export const loginUser = (username, password) => ({
  type: LOGIN_USER,
  payload: { username, password },
});

export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
});

export const loginUserFailed = (error) => ({
  type: LOGIN_USER_FAILED,
  payload: error,
});

export const registerUser = (email, password) => ({
  type: REGISTER_USER,
  payload: { email, password },
});

export const registerUserSuccess = (user) => ({
  type: REGISTER_USER_SUCCESS,
  payload: user,
});

export const registerUserFailed = (error) => ({
  type: REGISTER_USER_FAILED,
  payload: error,
});

export const verifyAccount = (email, code) => ({
  type: VERIFY_USER,
  payload: { email, code },
});

export const verifyAccountSuccess = (user) => ({
  type: VERIFY_USER_SUCCESS,
  payload: user,
});

export const verifyAccountFailed = (error) => ({
  type: VERIFY_USER_FAILED,
  payload: error,
});

export const resetPassword = (username, code, password) => ({
  type: RESET_PASSWORD,
  payload: { username, code, password },
});

export const resetPasswordSuccess = (user) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: user,
});

export const resetPasswordFailed = (error) => ({
  type: RESET_PASSWORD_FAILED,
  payload: error,
});

export const logoutUser = (username, history) => ({
  type: LOGOUT_USER,
  payload: { username, history },
});

export const forgetPassword = (username) => ({
  type: FORGET_PASSWORD,
  payload: { username },
});

export const forgetPasswordSuccess = (passwordResetStatus) => ({
  type: FORGET_PASSWORD_SUCCESS,
  payload: passwordResetStatus,
});

export const forgetPasswordFailed = (error) => ({
  type: FORGET_PASSWORD_FAILED,
  payload: error,
});

export const changePassword = (username, oldpassword, newpassword) => ({
  type: CHANGE_PASSWORD,
  payload: { username, oldpassword, newpassword },
});

export const changePasswordSuccess = (passwordChangeStatus) => ({
  type: CHANGE_PASSWORD_SUCCESS,
  payload: passwordChangeStatus,
});

export const changePasswordFailed = (error) => ({
  type: CHANGE_PASSWORD_FAILED,
  payload: error,
});
