import React from "react";
import { Button, Col, Row, FormGroup, Label, Input } from "reactstrap";
import { CSVLink } from "react-csv";
import BootstrapTable from "react-bootstrap-table-next";
import dataservice from "../../helpers/dataservice";

class WordpressCsv extends React.Component {
  state = {
    loading: false,
    filtered_articles_data: [],
    csv_file: [],
    include_draft: false,
    country_code: "IN",
  };
  articles_data_columns = [
    {
      dataField: "blog_id",
      text: "Blog Id",
      style: {
        width: "5%",
      },
      headerStyle: {
        width: "5%",
      },
      formatter: (cell, row) => {
        return (
          <a
            target="_blank"
            href={`https://wphappycredit.wpcomstaging.com/wp-admin/post.php?post=${cell}&action=edit`}
          >
            {cell}
          </a>
        );
      },
    },
    {
      dataField: "publishLink",
      text: "Publish Link",
      style: {
        width: "10%",
      },
      headerStyle: {
        width: "10%",
      },
      formatter: (cell, row) => {
        return (
          <div>
            <a target="_blank" href={cell}>
              Publish Link
            </a>
          </div>
        );
      },
    },
    {
      dataField: "title",
      text: "Title",
      style: {
        width: "20%",
      },
      headerStyle: {
        width: "20%",
      },
    },
    {
      dataField: "category",
      text: "Category",
      style: {
        width: "10%",
      },
      headerStyle: {
        width: "10%",
      },
    },
    {
      dataField: "status",
      text: "Status",
      style: {
        width: "7%",
      },
      headerStyle: {
        width: "7%",
      },
    },
    {
      dataField: "created_date",
      text: "Created Date",
      style: {
        width: "10%",
      },
      headerStyle: {
        width: "10%",
      },
    },
    {
      dataField: "updated_at",
      text: "Updated Date",
      style: {
        width: "10%",
      },
      headerStyle: {
        width: "10%",
      },
    },
  ];

  arrayToCsv(data) {
    if (data.length === 0) {
      alert("No Data");
      return [];
    }

    const header = Object.keys(data[0]);
    const rows = data.map((row) => Object.values(row));

    return [header, ...rows];
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  async fetchData() {
    try {
      let include_draft = this.state.include_draft;
      this.setState({ loading: true, filtered_articles_data: [] });
      const response = await dataservice.fetchJSON(
        "/word-press/get-all-wordpress-data",
        {
          body: {
            includeDraft: include_draft,
            country: this.state.country_code,
          },
          method: "POST",
          headers: { "Content-Type": "application/json" },
        }
      );
      let result_data = response ? response.data : [];
      let csv_file = this.arrayToCsv(result_data);
      this.setState({
        loading: false,
        filtered_articles_data: result_data,
        csv_file,
      });
    } catch (error) {
      console.error("Error fetching or writing data:", error);
      alert(error.message);
    }
  }
  // async componentDidMount() {
  //     await this.fetchData();
  // }
  async getWordPressData() {
    try {
      await this.fetchData();
    } catch (error) {
      alert(error.message);
    }
  }
  render() {
    let { loading, filtered_articles_data, csv_file, country_code } =
      this.state;
    return (
      <div style={{ marginTop: "30px" }}>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col>
            <h4 className="page-title">
              Published Articles ({filtered_articles_data.length}){" "}
            </h4>
          </Col>

          <Col>
            <Button
              disabled={loading}
              color={!loading ? "success" : "secondary"}
              onClick={() => {
                this.getWordPressData();
              }}
            >
              Get Data
            </Button>
          </Col>
          <Col>
            <h4>
              Add Draft &nbsp;
              <input
                name="includeDraft"
                type="checkBox"
                disabled={loading}
                checked={this.state.include_draft}
                onChange={() => {
                  this.setState({ include_draft: !this.state.include_draft });
                }}
              />
            </h4>
          </Col>
          <Col>
            <FormGroup>
              <Label>Country Code</Label>
              <Input
                type="select"
                name="country_code"
                value={country_code}
                onChange={(e) => {
                  this.handleChange(e);
                }}
              >
                <option value={"IN"}>India</option>
                <option value={"US"}>US</option>
              </Input>
            </FormGroup>
          </Col>

          <Col>
            {!loading ? (
              csv_file.length ? (
                <div className="page-title-box">
                  {
                    <CSVLink
                      data={csv_file}
                      filename={`blog_data_${country_code}${
                        this.state.include_draft ? "_with_drafts" : ""
                      }_${new Date().toISOString().slice(0,10)}.csv`}
                      className="btn btn-primary"
                    >
                      Download CSV
                    </CSVLink>
                  }
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </Col>
        </Row>
        {loading ? (
          <div
            className="spinner-border avatar-sm text-primary m-2"
            role="status"
          ></div>
        ) : (
          <div>
            <Row style={{ marginTop: "30px" }}>
              <BootstrapTable
                bootstrap4
                keyField="id"
                data={filtered_articles_data}
                columns={this.articles_data_columns}
                defaultSorted={[
                  {
                    dataField: "updated_at",
                    order: "desc",
                  },
                ]}
              />
            </Row>
          </div>
        )}
      </div>
    );
  }
}
export default WordpressCsv;
