import React from "react";
import { Row, Col } from "reactstrap";

function Title({ title }) {
  return (
    <div>
      <Row>
        <Col>
          <div className="page-title-box">
            <Row>
              <Col lg={12}>
                <h5 className="page-title">{title}</h5>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Title;
