// @flow
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILED,
  VERIFY_USER,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_FAILED,
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAILED,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
} from "../../constants/actionTypes";

import { getLoggedInUser } from "../../helpers/authUtils";

const INIT_STATE = {
  user: getLoggedInUser(),
  loading: false,
  error: "",
};

const defaultErrorMessage = "An error occurred";

const Auth = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true };
    case LOGIN_USER_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null };
    case LOGIN_USER_FAILED:
      const message = action.payload.message
        ? action.payload.message
        : defaultErrorMessage;
      return { ...state, error: message, loading: false };
    case REGISTER_USER:
      return { ...state, error: "", loading: true };
    case REGISTER_USER_SUCCESS:
      const regUser = action.payload.user
        ? action.payload.user
        : defaultErrorMessage;
      return { ...state, user: regUser, loading: false, error: null };
    case REGISTER_USER_FAILED:
      const messageReg = action.payload.message
        ? action.payload.message
        : defaultErrorMessage;
      return { ...state, error: messageReg, loading: false };
    case VERIFY_USER:
      return { ...state, error: "", loading: true };
    case VERIFY_USER_SUCCESS:
      const verifyUser = action.payload.user
        ? action.payload.user
        : defaultErrorMessage;
      return { ...state, user: verifyUser, loading: false, error: null };
    case VERIFY_USER_FAILED:
      const messageVerify = action.payload.message
        ? action.payload.message
        : defaultErrorMessage;
      return { ...state, error: messageVerify, loading: false };
    case RESET_PASSWORD:
      return { ...state, error: "", loading: true };
    case RESET_PASSWORD_SUCCESS:
      const resetPass = action.payload.user
        ? action.payload.user
        : defaultErrorMessage;
      return { ...state, user: resetPass, loading: false, error: null };
    case RESET_PASSWORD_FAILED:
      const messageReset = action.payload.message
        ? action.payload.message
        : defaultErrorMessage;
      return { ...state, error: messageReset, loading: false };
    case LOGOUT_USER:
      return { ...state, user: null };
    case FORGET_PASSWORD:
      return { ...state, passwordResetSuccessful: false, loading: true };
    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordResetSuccessful: action.payload ? true : false,
        loading: false,
        error: null,
      };
    case FORGET_PASSWORD_FAILED:
      const frgtPassMessage = action.payload.message
        ? action.payload.message
        : defaultErrorMessage;
      return {
        ...state,
        error: frgtPassMessage,
        passwordChangeStatus: false,
        loading: false,
      };
    case CHANGE_PASSWORD:
      return { ...state, passwordChangeStatus: false, loading: true };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordChangeStatus: action.payload ? true : false,
        loading: false,
        error: null,
      };
    case CHANGE_PASSWORD_FAILED:
      const changePassMessage = action.payload.message
        ? action.payload.message
        : defaultErrorMessage;
      return {
        ...state,
        error: changePassMessage,
        passwordChangeStatus: false,
        loading: false,
      };
    default:
      return { ...state };
  }
};

export default Auth;
