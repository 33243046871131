import React from "react";
import { Redirect } from "react-router-dom";
import { Route } from "react-router-dom";

import {
  isUserAuthenticated,
  getLoggedInUser,
  getRoutes,
} from "./helpers/authUtils";
import ReferralMap from "./pages/orders/ReferralMapAction";
import WordpressCsv from "./pages/wordpress/Wordpress_Csv";


// lazy load all the views
const Dashboard = React.lazy(() => import("./pages/dashboards/"));

// auth
const Login = React.lazy(() => import("./pages/auth/Login"));
const Logout = React.lazy(() => import("./pages/auth/Logout"));
const ForgotPassword = React.lazy(() =>
  import("./pages/account/ForgotPassword")
);
const ResetPassword = React.lazy(() => import("./pages/account/ResetPassword"));
const Register = React.lazy(() => import("./pages/account/Register"));
const Verify = React.lazy(() => import("./pages/account/Verify"));
const ConfirmAccount = React.lazy(() => import("./pages/account/Confirm"));
const Verified = React.lazy(() => import("./pages/account/Verified"));
const Transactions = React.lazy(() => import("./pages/orders/Transactions"));
const PostbackDashboard = React.lazy(() =>
  import("./pages/orders/PostbackDashboard")
);
const ChangePassword = React.lazy(() => import("./pages/ChangePassword"));
const NotFound = React.lazy(() => import("./pages/NotFound"));

const PublishStories = React.lazy(() =>
  import("./pages/story/stories/PublishStories")
);
const AllStories = React.lazy(() => import("./pages/story/stories/AllStories"));
const AddStory = React.lazy(() => import("./pages/story/stories/AddStory"));

const StoryTemplates = React.lazy(() => import("./pages/story/StoryTemplates"));
const PageTemplateList = React.lazy(() =>
  import("./pages/story/page-template/PageTemplateList")
);
const AddPageTemplate = React.lazy(() =>
  import("./pages/story/page-template/AddPageTemplate")
);
const EditPageTemplate = React.lazy(() =>
  import("./pages/story/page-template/EditPageTemplate")
);


const EditSubCategory = React.lazy(() =>
  import("./pages/story/category/EditCategory")
);
const AddSubCategory = React.lazy(() =>
  import("./pages/story/category/AddCategory")
);
const SubCategoryList = React.lazy(() =>
  import("./pages/story/category/CategoryList")
);
const EditStoryMerchant = React.lazy(() =>
  import("./pages/story/merchant/EditStoryMerchant")
);
const AddStoryMerchant = React.lazy(() =>
  import("./pages/story/merchant/AddStoryMerchant")
);
const StoryMerchantList = React.lazy(() =>
  import("./pages/story/merchant/StoryMerchantList")
);
const UploadedGifs = React.lazy(() =>
  import("./pages/story/gifs/UploadedGifs")
);
const UploadGifs = React.lazy(() => import("./pages/story/gifs/UploadGifs"));

const Orders = React.lazy(() => import("./pages/dataSet"));
const Order = React.lazy(() => import("./pages/Order"));
const User = React.lazy(() => import("./pages/User"));
const EscalateList = React.lazy(() => import("./pages/EscalateExcel"));
const CreateOrder = React.lazy(() => import("./pages/CreateOrder"));
const CampaignList = React.lazy(() => import("./pages/campaign/CampaignList"));
const AddCampaign = React.lazy(() => import("./pages/campaign/AddCampaign"));
const EditCampaign = React.lazy(() => import("./pages/campaign/EditCampaign"));
const CouponList = React.lazy(() => import("./pages/coupon/CouponList"));
const AddCoupon = React.lazy(() => import("./pages/coupon/AddCoupon"));
const EditCoupon = React.lazy(() => import("./pages/coupon/EditCoupon"));
const MerchantList = React.lazy(() => import("./pages/merchant/MerchantList"));
const AddMerchant = React.lazy(() => import("./pages/merchant/AddMerchant"));
const EditMerchant = React.lazy(() => import("./pages/merchant/EditMerchant"));
const CategoryList = React.lazy(() => import("./pages/category/CategoryList"));
const AddCategory = React.lazy(() => import("./pages/category/AddCategory"));
const EditCategory = React.lazy(() => import("./pages/category/EditCategory"));
const GiftCardCampaignList = React.lazy(() => import("./pages/giftcard/GiftCardCampaignList"));
const AddGiftCardCampaign = React.lazy(() => import("./pages/giftcard/AddGiftCardCampaign"));
const EditGiftCardCampaign = React.lazy(() => import("./pages/giftcard/EditGiftCardCampaign"));
const AddGiftCardWebsitePage = React.lazy(() => import("./pages/giftcard/AddGiftCardWebsitePage"));
const CaseMatrix = React.lazy(() => import("./pages/CaseMatrix"));
const SearchUser = React.lazy(() => import("./pages/SearchUser"));
const UserSupportRequest = React.lazy(() => import("./pages/UserSupportRequest"));
const StealDealOrders = React.lazy(() => import("./pages/StealDealOrders"));
const DeleteUser = React.lazy(() => import("./pages/DeleteUser"));
const UploadBlogImage = React.lazy(() => import("./pages/UploadBlogImage"));
const Installs = React.lazy(() => import("./pages/analytics/Installs"));
const GiftOrderDetails = React.lazy(()=>import ("./pages/GiftOrderdetail"));
const GiftOrder =React.lazy(()=> import ("./pages/orders/GiftOrder"));
const WordPressArticles = React.lazy(()=> import ("./pages/wordpress/BlogArticle"));
const UploadFileImage  = React.lazy(()=> import ("./pages/UploadFileImage"));
const WordPressProductArticle = React.lazy(()=>import("./pages/wordpress/ProductArticle"));
const BlogMapContent = React.lazy(()=>import("./pages/wordpress/MapContent"));
const AddSeoRedirects = React.lazy(()=>import("./pages/wordpress/addredirect"));
const SeoRedirectsList = React.lazy(()=>import("./pages/wordpress/SeoRedirectsList"));
const AddKeyWord = React.lazy(()=>import("./pages/wordpress/AddKeyWord"));
const KeyWordList = React.lazy(()=>import("./pages/wordpress/KeyWordList"));
const ProductTitleBreaking =React.lazy(()=>import("./pages/wordpress/ProductTitleBreaking"));
const InterLinkArticles =React.lazy(()=>import("./pages/wordpress/InterLinkArticles"));
const EditSeoMetaDatas = React.lazy(()=>import("./pages/wordpress/SeoUpdate"));


const MerchantOrders = React.lazy(() =>
  import("./pages/analytics/MerchantOrders")
);

const OrderUserAssignment = React.lazy(() =>
  import("./pages/admin/OrderAssignment")
);
const AgentProductivityTracker = React.lazy(() =>
  import("./pages/admin/AgentProductivityTracker")
);
const BulkOrderAction = React.lazy(() =>
  import("./pages/orders/BulkOrderAction")
);
const BulkOrderStatus = React.lazy(() =>
  import("./pages/orders/BulkOrderStatus")
);
const TransactionAnalytics = React.lazy(() =>
  import("./pages/analytics/TransactionAnalytics")
);

const RoutePermission = React.lazy(() =>
  import("./pages/admin/RoutePermission")
);
const ManualAction = React.lazy(() =>
  import("./pages/admin/ManualAction")
);
const AddRoute = React.lazy(() =>
  import("./pages/admin/AddRoute")
);
const EditRoutePermission = React.lazy(() =>
  import("./pages/admin/EditRoutePermission")
);
const UninstallImport = React.lazy(() =>
  import("./pages/orders/UninstallImport")
);
const UnProcessedOrders = React.lazy(() =>
  import("./pages/orders/UnProcessedOrders")
);
const CashoutDashboard = React.lazy(() =>
  import("./pages/orders/CashoutDashboard")
);

const CashoutTransactionDetail = React.lazy(() =>
import("./pages/CashoutTransactionDetail"));
const StealDeal =React.lazy( ()=>{
   return   import ("./pages/orders/StealDeal")
});
const StealDealCreateProduct =React.lazy(()=>{
  return import ("./pages/orders/StealDealProductCreate")
})
const EditStealDealProduct = React.lazy(()=>{
  return import ("./pages/orders/EditStealDeal")
})
const StealDealCreateMerchant = React.lazy(()=>import("./pages/orders/StealDealCreateMerchant"));
const XoxoGiftOrderDetail = React.lazy(() =>
import("./pages/XoxoGiftOrderDetail")
)
const XoxoGiftVoucher =React.lazy(()=>{
  return import("./pages/orders/XoxoGiftVoucher")
})
const RazorpayPaymentDetail = React.lazy(() =>
import("./pages/RazorpayPaymentDetail")
);

const GiftCardDeliveryDetails = React.lazy(() =>
import("./pages/giftcard/GiftCardDeliveryDetailApi")
)
const SendMoengageEvent = React.lazy(() =>
import("./pages/SendMoengageEvent")
);

const EditAishbackRates = React.lazy(()=>
  import("./pages/uptocampaign/editaishbackrate")
)


// handle auth and authorization
const StealDealMerchantlist = React.lazy(()=>import("./pages/orders/StealDealMerchantList"));
const StealDealMerchantById = React.lazy(()=>import("./pages/orders/StealDealMerchnatEdit"));
const StealDealDetails =React.lazy(()=>import("./pages/orders/StealDealDetailspage"));
const StealDealTelegramData = React.lazy(()=>import("./pages/orders/TelegramData"));
const GCPaymentRecords = React.lazy(()=>import("./pages/admin/GCPaymentRecord"));
const Postbacks = React.lazy(()=>import("./pages/analytics/Postbacks"));
const Revenue = React.lazy(()=>import("./pages/admin/Revenue"));
const AddBlogWidget = React.lazy(()=>import("./pages/wordpress/AddBlogWidget"))
const PrivateRoute = ({ component: Component, roles, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const isAuthTokenValid = isUserAuthenticated();

      if (!isAuthTokenValid) {
        // not logged in so redirect to login page with the return url
        // console.log(props.location, "props");
        return (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location.pathname + props.location.search },
            }}
          />
        );
      }

      const loggedInUser = getLoggedInUser();
      // console.log(loggedInUser.routes, "routes");
      // check if route is restricted by role
      if (
        rest &&
        rest.path !== "/" &&
        (!loggedInUser.routes || loggedInUser.routes.indexOf(rest.path) === -1)
      ) {
        return <Redirect to={{ pathname: "/not-allowed" }} />;
      }
      // authorised so return component
      return <Component {...props} />;
    }}
  />
);

const routes = [
  // auth and account
  { path: "/login", name: "Login", component: Login, route: Route },
  { path: "/logout", name: "Logout", component: Logout, route: Route },
  {
    path: "/forgot-password",
    name: "Forget Password",
    component: ForgotPassword,
    route: Route,
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    component: ResetPassword,
    route: Route,
  },
  {
    path: "/change-password",
    name: "Change Password",
    component: ChangePassword,
    route: Route,
  },
  { path: "/register", name: "Register", component: Register, route: Route },
  { path: "/verify", name: "Verify", component: Verify, route: Route },
  {
    path: "/confirm",
    name: "Confirm",
    component: ConfirmAccount,
    route: Route,
  },
  {
    path: "/verified",
    name: "Verified",
    component: Verified,
    route: Route,
  },

  // Dashboard
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    route: PrivateRoute,
    roles: ["Admin", "merchant_manager", "merchant_admin", "sales-person"],
  },

  // Analytics
  {
    path: "/installs-analytics",
    name: "Installs",
    component: Installs,
    route: PrivateRoute,
  },
  {
    path: "/postback-analytics",
    name: "Postbacks",
    component: Postbacks,
    route: PrivateRoute,
  },
  {
    path: "/revenue-analytics",
    name: "Revenue",
    component: Revenue,
    route: PrivateRoute,
  },
  {
    path: "/transactions-analytics",
    name: "TransactionAnalytics",
    component: TransactionAnalytics,
    route: PrivateRoute,
  },
  {
    path: "/merchant-orders-analytics",
    name: "MerchantOrders",
    component: MerchantOrders,
    route: PrivateRoute,
  },

  // Admin
  {
    path: "/case-matrix",
    name: "CaseMatrix",
    component: CaseMatrix,
    route: PrivateRoute,
    roles: ["Admin"],
  },
  {
    path: "/upload-blog-image",
    name: "UploadBlogImage",
    component: UploadBlogImage,
    route: PrivateRoute,
    roles: ["Admin"],
  },
  {
    path: "/order-user-assignment",
    name: "OrderUserAssignment",
    component: OrderUserAssignment,
    route: PrivateRoute,
    roles: ["Admin"],
  },
  {
    path: "/audit-agent",
    name: "AgentProductivityTracker",
    component: AgentProductivityTracker,
    route: PrivateRoute,
    roles: ["Admin"],
  },
  {
    path: "/bulk-order-action",
    name: "BulkOrderAction",
    component: BulkOrderAction,
    route: PrivateRoute,
  },
  {
    path: "/bulk-order-status",
    name: "BulkOrderStatus",
    component: BulkOrderStatus,
    route: PrivateRoute,
  },
  {
    path: "/cashout-data",
    name: "CashoutDashboard",
    component: CashoutDashboard,
    route: PrivateRoute,
  },
  {
    path: "/xoxo-get-gift-voucher",
    name: "XoxoGiftVoucher",
    component: XoxoGiftVoucher,
    route: PrivateRoute,
    roles: ["Admin"]
  },
  {
    path: "/xoxo-gift-order-detail",
    name: "XoxoGiftOrderDetail",
    component: XoxoGiftOrderDetail,
    route: PrivateRoute,
    roles: ["Admin"]
  },
  {
    path: "/razorpay-payment-detail",
    name: "RazorpayPaymentDetail",
    component: RazorpayPaymentDetail,
    route: PrivateRoute,
    roles: ["Admin"]
  },
  {
    path: "/giftcard-delivery-detail",
    name: "GiftCardDeliveryDetails",
    component: GiftCardDeliveryDetails,
    route: PrivateRoute,
  },
  {
    path: "/cashout-transaction-detail",
    name: "CashoutTransactionDetail",
    component: CashoutTransactionDetail,
    route: PrivateRoute,
  },

  {
    path: "/send-moengage-event",
    name: "SendMoengageEvent",
    component: SendMoengageEvent,
    route: PrivateRoute,
  },

  {
    path: "/route-permissions",
    name: "RoutePermission",
    component: RoutePermission,
    route: PrivateRoute,
    roles: ["Admin"]
  },
  {
    path: "/manual-action",
    name: "ManualAction",
    component: ManualAction,
    route: PrivateRoute,
  },
  {
    path: "/upload-image-file",
    name: "UploadImageFile",
    component: UploadFileImage,
    route: PrivateRoute,
  },
  {
    path: "/create-route",
    name: "AddRoute",
    component: AddRoute,
    route: PrivateRoute,
  },
  {
    path: "/update-route",
    name: "EditRoutePermission",
    component: EditRoutePermission,
    route: PrivateRoute
    
  },
  {
    path: "/get-gc-order-details",
    name: "GCPaymentRecords",
    component: GCPaymentRecords,
    route: PrivateRoute,
    roles: ["Admin"],
  },
  // Story
  {
    path: "/create-story",
    name: "AddStory",
    component: AddStory,
    route: PrivateRoute,
  },
  {
    path: "/all-stories",
    name: "AllStories",
    component: AllStories,
    route: PrivateRoute,
  },
  {
    path: "/publish-stories",
    name: "PublishStories",
    component: PublishStories,
    route: PrivateRoute,
  },
  {
    path: "/story-templates",
    name: "StoryTemplates",
    component: StoryTemplates,
    route: PrivateRoute,
  },
  {
    path: "/page-templates",
    name: "PageTemplateList",
    component: PageTemplateList,
    route: PrivateRoute,
  },
  {
    path: "/create-page-template",
    name: "AddPageTemplate",
    component: AddPageTemplate,
    route: PrivateRoute,
  },
  {
    path: "/update-page-template",
    name: "EditPageTemplate",
    component: EditPageTemplate,
    route: PrivateRoute,
  },
  {
    path: "/sub-categories",
    name: "SubCategoryList",
    component: SubCategoryList,
    route: PrivateRoute,
  },
  {
    path: "/create-sub-category",
    name: "AddSubCategory",
    component: AddSubCategory,
    route: PrivateRoute,
  },
  {
    path: "/update-sub-category",
    name: "EditSubCategory",
    component: EditSubCategory,
    route: PrivateRoute,
  },
  {
    path: "/story-merchants",
    name: "StoryMerchantList",
    component: StoryMerchantList,
    route: PrivateRoute,
  },
  {
    path: "/create-story-merchant",
    name: "AddStoryMerchant",
    component: AddStoryMerchant,
    route: PrivateRoute,
  },
  {
    path: "/update-story-merchant",
    name: "EditStoryMerchant",
    component: EditStoryMerchant,
    route: PrivateRoute,
  },
  {
    path: "/upload-gifs",
    name: "UploadGifs",
    component: UploadGifs,
    route: PrivateRoute,
  },
  {
    path: "/uploaded-gifs",
    name: "UploadedGifs",
    component: UploadedGifs,
    route: PrivateRoute,
  },
  // Campaign
  {
    path: "/update-campaign",
    name: "EditCampaign",
    component: EditCampaign,
    route: PrivateRoute,
  },
  {
    path: "/create-campaign",
    name: "AddCampaign",
    component: AddCampaign,
    route: PrivateRoute,
  },
  {
    path: "/blog-articles-list-page",
    name: "Blog Article List Page",
    component: WordPressArticles,
    route: PrivateRoute,
  },
  {
    path: "/content-pre-processing",
    name: "Content Pre Processing",
    component: WordPressProductArticle,
    route: PrivateRoute,
  },
  {
    path: "/blog-map-content",
    name: "Blog  Map Content",
    component: BlogMapContent,
    route: PrivateRoute,

  },
  {
    path: "/add-alsoexplore-sections-by-blogid",
    name: "AddBlogWidget",
    component: AddBlogWidget,
    route: PrivateRoute,
  },
  {
    path: "/wordpress-csv-file",
    name: "Word Press Csv",
    component: WordpressCsv,
    route: PrivateRoute,
  },{
    path: "/get-seo-redirects",
    name: "getSeoRedirects",
    component: SeoRedirectsList,
    route: PrivateRoute,
  },
  {
    path: "/add-keyword",
    name: "addKeyWord",
    component: AddKeyWord,
    route: PrivateRoute,
  },
  {
    path: "/keyword-list",
    name: "keyWordList",
    component: KeyWordList,
    route: PrivateRoute,
  },
  {
    path: "/edit-seo-meta-datas",
    name: "EditSeoMetaDatas",
    component: EditSeoMetaDatas,
    route: PrivateRoute,
  },
  
  {
    path: "/product-title-breaking",
    name: "productTitleBreaking",
    component: ProductTitleBreaking,
    route: PrivateRoute,
  },
  {

    path: "/link-blog-articles",
    name: "ArticlesInterLink",
    component: InterLinkArticles,
    route: PrivateRoute,
  },
  {
    path: "/add-seo-redirects",
    name: "addSeoRedirects",
    component: AddSeoRedirects,
    route: PrivateRoute,
  },
  {
    path: "/campaigns",
    name: "CampaignList",
    component: CampaignList,
    route: PrivateRoute,
  },
    // Coupon
    {
      path: "/update-coupon",
      name: "EditCoupon",
      component: EditCoupon,
      route: PrivateRoute,
    },
    {
      path: "/create-coupon",
      name: "AddCoupon",
      component: AddCoupon,
      route: PrivateRoute,
    },
    {
      path: "/coupons",
      name: "CouponList",
      component: CouponList,
      route: PrivateRoute,
    },
  // Merchant
  {
    path: "/update-merchant",
    name: "EditMerchant",
    component: EditMerchant,
    route: PrivateRoute,
  },
  {
    path: "/create-merchant",
    name: "AddMerchant",
    component: AddMerchant,
    route: PrivateRoute,
  },
  {
    path: "/merchants",
    name: "MerchantList",
    component: MerchantList,
    route: PrivateRoute,
  },
  // Category
  {
    path: "/update-category",
    name: "EditCategory",
    component: EditCategory,
    route: PrivateRoute,
  },
  {
    path: "/create-category",
    name: "AddCategory",
    component: AddCategory,
    route: PrivateRoute,
  },
  {
    path: "/categories",
    name: "CategoryList",
    component: CategoryList,
    route: PrivateRoute,
  },
    // GiftCards
    {
      path: "/update-giftcard-campaign",
      name: "EditGiftCardCampaign",
      component: EditGiftCardCampaign,
      route: PrivateRoute,
    },
    {
      path: "/create-giftcard-campaign",
      name: "AddGiftCardCampaign",
      component: AddGiftCardCampaign,
      route: PrivateRoute,
    },
    {
      path: "/create-giftcard-campaign-website",
      name: "AddGiftCardWebsitePage",
      component: AddGiftCardWebsitePage,
      route: PrivateRoute,
    },
    {
      path: "/giftcard-campaigns",
      name: "GiftCardCampaignList",
      component: GiftCardCampaignList,
      route: PrivateRoute,
    },
  // Order
  {
    path: "/orders",
    name: "Orders",
    component: Orders,
    route: PrivateRoute,
  },
  {
    path: "/transactions",
    name: "Transactions",
    component: Transactions,
    route: PrivateRoute,
    // roles: ["Admin", "merchant_manager", "merchant_admin", "sales-person"],
  },
  {
       path:"/giftorders",
       name:"Gift Order",
       component :GiftOrder,
       route: PrivateRoute,
  },
  {
    path: "/postback-dashboard",
    name: "PostbackDashboard",
    component: PostbackDashboard,
    route: PrivateRoute,
    // roles: ["Admin", "merchant_manager", "merchant_admin", "sales-person"],
  },
  {
    path: "/uninstall-import",
    name: "UninstallImport",
    component: UninstallImport,
    route: PrivateRoute,
    // roles: ["Admin", "merchant_manager", "merchant_admin", "sales-person"],
  },
  {
    path:"/update-aishback-rate-by-id",
    name:"EditAishbackRates",
    component:EditAishbackRates,
    route:PrivateRoute
  },
  {

    path:"/steal-deal",
    name:"StealDeal",
    component:StealDeal,
    route:PrivateRoute
    
  },
  {
    path:"/steal-deal-create-product",
    name:"Steal Deal Create Product",
    component:StealDealCreateProduct,
    route:PrivateRoute  
  },
  {
    path:"/edit-steal-deal-product",
    name:"Edit Steal Deal Product",
    component:EditStealDealProduct,
    route:PrivateRoute
  },
  {
    path:"/steal-deal-create-merchant",
    name:"Steal Deal Create Merchant",
    component:StealDealCreateMerchant,
    route:PrivateRoute
  },
  {
     path:"/steal-deal-merchant-by-id",
     name:"Steal Deal MerchantBy Id",
     component:StealDealMerchantById,
     route:PrivateRoute
  },
  {
    path:"/steal-deal-merchant-list",
    name:"Steal Deal Merchant list",
    component:StealDealMerchantlist,
    route:PrivateRoute
  },
  {
    path:"/steal-deal-product-details",
    name:"Steal Deal Product Details",
    component:StealDealDetails,
    route:PrivateRoute,

  },
  {
    path:"/steal-deal-telegram-data",
    name:"Steal Deal Telegram Data",
    component:StealDealTelegramData,
    route:PrivateRoute
    

  },
  {
    path:"/map-referral",
    name:"ReferralMap",
    component: ReferralMap,
    route: PrivateRoute,
  },
  {
    path: "/escalate-list",
    name: "EscalateList",
    component: EscalateList,
    route: PrivateRoute,
  },
  {
    path: "/order",
    name: "Order",
    component: Order,
    route: PrivateRoute,
  },
  {
    path:"/giftorder",
    name: "GiftOrderDetail",
    component: GiftOrderDetails,
    route: PrivateRoute,
  },
  {
    path: "/user",
    name: "User",
    component: User,
    route: PrivateRoute,
  },
  {
    path: "/search-user",
    name: "SearchUser",
    component: SearchUser,
    route: PrivateRoute,
  },
  {
    path: "/user-support-request",
    name: "UserSupportRequest",
    component: UserSupportRequest,
    route: PrivateRoute,
  },
  {
    path: "/steal-deal-orders",
    name: "StealDealOrders",
    component: StealDealOrders,
    route: PrivateRoute,
  },

  {
    path: "/delete-user",
    name: "DeleteUser",
    component: DeleteUser,
    route: PrivateRoute,
  },
  {
    path: "/create-order",
    name: "Create Order",
    component: CreateOrder,
    route: PrivateRoute,
  },
  {
    path: "/unassigned-orders",
    name: "Unprocessed Order",
    component: UnProcessedOrders,
    route: PrivateRoute,
  },
  // General
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
    route: PrivateRoute,
  },
  {
    path: "/not-allowed",
    name: NotFound,
    component: NotFound,
    route: Route,
  },
  {
    path: "*",
    component: NotFound,
    route: PrivateRoute,
  },
  
];
const getRoutesData = () => {
  var routeData = getRoutes();
  return routeData;
};

export { routes, getRoutesData, PrivateRoute };
