const {
  ADMIN,
  MARKETING_MANAGER,
  SUPPORT_MANAGER,
  SUPPORT_EXECUTIVE,
  MARKETING_EXECUTIVE,
  SUPPORT_ADMIN,
  BLOG_CONTRIBUTOR,
  CONTENT_EXECUTIVE,
  CONTENT_MANAGER,
} = require("./constants/roles");

module.exports = {
  name: "HappyCredit Business",
  hostname: process.env.REACT_APP_HOSTNAME,
  merchantid: 11,
  version: "1.0",
  env: process.env.NODE_ENV,
  routes: {
    orders: process.env.REACT_APP_HIDE_ORDERS === "1" ? false : true,
    stories: process.env.REACT_APP_HIDE_STORIES === "1" ? false : true,
    blog: process.env.REACT_APP_HIDE_BLOG === "1" ? false : true,
    campaigns: process.env.REACT_APP_HIDE_CAMPAIGNS === "1" ? false : true,
    categories: process.env.REACT_APP_HIDE_CATEGORIES === "1" ? false : true,
    merchants: process.env.REACT_APP_HIDE_MERCHANTS === "1" ? false : true,
    reports: process.env.REACT_APP_HIDE_REPORTS === "1" ? false : true,
    admin: process.env.REACT_APP_HIDE_ADMIN === "1" ? false : true,
    dashboard: process.env.REACT_APP_HIDE_DASHBOARD === "1" ? false : true,
    stealdeal: process.env.REACT_APP_HIDE_STEALDEAL === "1" ? false : true,
  },
  mobilemerchants: ["2050", "17"],
  mobilecampaigns: ["327", "207"],
  escalateorderid: ["2050"],
  hidequality: ["Admin", "support_manager"],
  bucketimgpath: "static/images/app/partners/",
  storyimgpath: "stories/uploadimages/",
  blogbucket: "uploads.happycredit.app",
  blogbucketpath: "cloudinary/blog/",
  merchantbucketpath: "cloudinary/merchant/",
  campaignbucketpath: "cloudinary/campaign/",
  stealdealbucketpath: "cloudinary/stealdeal/",
  stealdealbucketpathopt: "cloudinary_opt/stealdeal",
  campaignbucketpathopt: "cloudinary_opt/campaign/",
  merchantbucketpathopt: "cloudinary_opt/merchant/",
  categorybucketpath: "cloudinary/category/",
  categorybucketpathopt: "cloudinary_opt/category/",
  cardbucketpath: "cloudinary/static/images/app/partners/",
  cloudinaryaishback: " https://res.cloudinary.com/aishback/image/upload/h_",
  blogbucketpathopt: "cloudinary_opt/blog/",
  bucketurl: process.env.REACT_APP_IMAGEHOST,
  storybucket: "uploads.happycredit.app",
  hcappbucket: "static.happycredit.cards",
  imagehosturl: "https://happycredit.in/",
  port: process.env.REACT_APP_PORT,
  apihost: process.env.REACT_APP_APIHOST,
  apibase: process.env.REACT_APP_APIHOST + "/api/backend",
  storypageorder: [0, 5, 1, 2, 3],
  storygifimage:
    "https://happycredit.in/static/images/app/partners/coming_soon.png",
  apibasestory: process.env.REACT_APP_APIHOST + "/api/story",
  apisub: "/backend",
  pagecount: 100,
  displaypagelimit: 5,
  ordercount: 500,
  storycount: 4,
  maxpagenums: 5,
  bksecret: process.env.REACT_APP_BK_SECRET,
  expDays: process.env.REACT_APP_EXPDAYS,
  tabs_access: {
    Story: [ADMIN, MARKETING_MANAGER, MARKETING_EXECUTIVE],
    Order: [ADMIN, SUPPORT_MANAGER, SUPPORT_EXECUTIVE],
    Campaign: [
      ADMIN,
      MARKETING_MANAGER,
      MARKETING_EXECUTIVE,
      SUPPORT_MANAGER,
      SUPPORT_EXECUTIVE,
    ],
    Merchant: [
      ADMIN,
      MARKETING_MANAGER,
      MARKETING_EXECUTIVE,
      SUPPORT_MANAGER,
      SUPPORT_EXECUTIVE,
    ],
    Category: [ADMIN, MARKETING_MANAGER],
    Admin: [ADMIN, SUPPORT_ADMIN],
    Analytics: [
      ADMIN,
      MARKETING_MANAGER,
      MARKETING_EXECUTIVE,
      SUPPORT_MANAGER,
      SUPPORT_EXECUTIVE,
    ],
    Blog: [
      ADMIN,
      MARKETING_MANAGER,
      MARKETING_EXECUTIVE,
      BLOG_CONTRIBUTOR,
      CONTENT_EXECUTIVE,
      CONTENT_MANAGER,
    ],
  },
  required_app_version: 50,
  contact_customer_email_subject: "Delivery Screenshot Needed - HappyCredit",
  payment_partner_url: "https://partners.hypto.in/dashboard?q=",
  help_email: "help@happyycredit.in",
  abak_env:
    process.env.REACT_APP_ABAK_ENV && process.env.REACT_APP_ABAK_ENV === "prod"
      ? "production"
      : "staging",
  contact_customer_email_header: "",
  contact_customer_email_footer: "\n\nThank You\n\nRegards \n<agent name>",
  cashback_comment_limit: 120,
  skip_postback_days: 10,
  blog_image_max_height: 520,
  cashout_analysis_amount: 100,
  flipkart_url_exclude: ["affid", "affExtParam1", "affExtParam2"],
  show_sms: false,
  word_press: {
    banner_image: {
      max_width: 1200,
      max_height: 674,
    },
    inline_image: {
      max_width: 855,
      max_height: 400,
    },
    tags: [
      {
        id: 248976,
        name: "content_type_in_credit_card",
        slug: "credit-card",
      },
      {
        id: 248977,
        name: "content_type_in_how_to",
        slug: "how-to",
      },
      {
        id: 248975,
        name: "content_type_in_product",
        slug: "best-products",
      },
      {
        id: 248974,
        name: "country_code_us",
        slug: "us",
      },
    ],
  },
  pre_processing_articles: {
    allowed_domain: ["amazon.in", "amazon.com"],
  },
  website_link:
    process.env.REACT_APP_ABAK_ENV === "prod"
      ? "https://happycredit.in"
      : "https://staging.wamp.happycredit.in",
  staging_website: "https://staging.wamp.happycredit.in",
  production_website: "https://happycredit.in",
  country_tag_code: {
    248974: {
      country_code: "US",
    },
  },
  testing_blog_id: 5365,
  product_tag_id: 248975,
  enable_asin_widget: process.env.REACT_APP_ENABLE_ASIN_WIDGET || 0,
};
