const crypto = require("crypto");
const config = require("./../config");
const authUtils = require("./authUtils");
const stringSimilarity = require("string-similarity");
/**
 * Gets params from url
 */
const getParams = (location) => {
  const searchParams = new URLSearchParams(location.search);
  return {
    page: searchParams.get("page") || "",
    invoiceId: searchParams.get("invoiceId") || "",
  };
};

const getURLGetParam = (location, param) => {
  const searchParams = new URLSearchParams(location.search);
  return {
    value: searchParams.get(param) || "",
  };
};

const encString = (req) => {
  var reqData = req.method === "GET" ? req.query : req.body;
  if (config.bksecret == null) {
    throw new Error("Setup error : bksecret cannot be empty.");
  }
  const hash = crypto
    .createHmac("sha256", config.bksecret)
    .update(reqData)
    .digest("hex");
  return {
    hash: "Bearer " + hash,
  };
};

const authData = () => {
  return {
    tokens: authUtils.isUserAuthenticated()
      ? authUtils.getUserData().tokens
      : null,
    username: authUtils.isUserAuthenticated()
      ? authUtils.getUserData().username
      : null,
    roles: authUtils.isUserAuthenticated()
      ? authUtils.getUserData().roles
      : null,
    userId: authUtils.isUserAuthenticated()
      ? authUtils.getUserData().userId
      : null,
  };
};

const authDataRefresh = () => {
  return {
    tokens: authUtils.getUserData().tokens
      ? authUtils.getUserData().tokens
      : null,
    username: authUtils.getUserData().username
      ? authUtils.getUserData().username
      : null,
  };
};

function getImageDimensions(imageUrl) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      resolve({
        width: img.naturalWidth,
        height: img.naturalHeight,
      });
    };

    img.onerror = (error) => {
      reject(error);
    };
  });
}

function breakString(inputString) {
  // Get the length of the original string
  let original_string_length = inputString.length;

  // Check if the string is shorter than 64 characters
  if (original_string_length < 64) {
    // Ignore and do nothing if the string is shorter than 64 characters
    return inputString;
  }

  // Priority list of separators
  const separators = [",", "|", ":", "("];

  // Iterate over the string to find the first occurring separator
  for (let i = 0; i < inputString.length; i++) {
    // Get the current character in the string
    const symbol = inputString[i];

    // Check if the current symbol is one of the separators
    if (separators.includes(symbol)) {
      // Find the index of the first occurring separator
      const index = inputString.indexOf(symbol);

      // Get the characters before and after the separator
      const firstPart = inputString.slice(0, index);
      const secondPart = inputString.slice(index + 1);

      // Check if the split parts meet the specified conditions
      //(firstPart.length > 32 || firstPart.length >= 0.25 * original_string_length) &&
      if (
        secondPart.length > 32 ||
        secondPart.length >= 0.25 * original_string_length
      ) {
        // If the separator is '(', add it to the second part
        // Otherwise, join the parts with '###'
        return symbol === "("
          ? `${firstPart}###(${secondPart}`
          : `${firstPart}###${secondPart}`;
      }
    }
  }

  // If no suitable separator is found, return the original string
  return inputString;
}

function extractHref(htmlString) {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlString;

  const anchorElement = tempDiv.querySelector("a");
  if (anchorElement) {
    return anchorElement.getAttribute("href");
  } else {
    return null; // Return null if no anchor element is found
  }
}

function generateKeyWordHTMLContent(data) {
  let htmlContent = "";

  // Iterate over properties of data and create HTML content
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const value = data[key];

      // Check if the key is "faqs" and split the value by "###"
      const lines = key === "faqs" ? value.split("###") : [value];

      // Create HTML content for each line
      if (key === "faqs") {
        htmlContent += `<div style="margin-bottom: 10px; font-size: 16px;">${key}:</div>`;
        // Display three lines
        for (let i = 0; i < lines.length; i++) {
          htmlContent += `<div style="margin-bottom: 5px; font-size: 14px; color: green;">${lines[i]}</div>`;
        }
      } else {
        lines.forEach((line) => {
          htmlContent += `<div style="margin-bottom: 10px; font-size: 16px;">${
            key === "id" ? "Keyword id" : key
          }: ${line}</div>`;
        });
      }
    }
  }

  return htmlContent;
}
// Function to count the occurrences of a specified placeholder in a string
function countPlaceholderOccurrences(inputString, placeholder) {
  // Create a regular expression with the 'g' flag for global search
  const regex = new RegExp(placeholder, "g");

  // Use the regular expression to find all matches in the input string
  const matches = inputString.match(regex);

  // If matches are found, return the count; otherwise, return 0
  return matches ? matches.length : 0;
}

function calculateSimilarityV2(string, array, similarity_check) {
  const similarities = array.map((item) => {
    return {
      similarity: stringSimilarity.compareTwoStrings(string, item.string) * 100,
      string: item.string,
      actual_string: item.actual_string,
    };
  });
  let similar_objects = [];
  for (let i = 0; i < similarities.length; i++) {
    if (similarities[i].similarity >= similarity_check) {
      similar_objects.push({
        similarity: similarities[i].similarity,
        string: similarities[i].string,
        actual_string: similarities[i].actual_string,
      });
    }
  }
  console.log("Similar",similarities)
  return { result: similar_objects.length > 0 ? true : false, similar_objects };
}

function calculateStringSimilarScore(s1, s2) {
  return stringSimilarity.compareTwoStrings(s1, s2) * 100;
}

export default {
  getParams,
  getURLGetParam,
  encString,
  authData,
  authDataRefresh,
};
export {
  countPlaceholderOccurrences,
  generateKeyWordHTMLContent,
  extractHref,
  breakString,
  getImageDimensions,
  getParams,
  getURLGetParam,
  encString,
  authData,
  authDataRefresh,
  calculateSimilarityV2,
};
