// @flow
import {
  TOKEN_REFRESH,
  TOKEN_REFRESH_RECEIVED,
  TOKEN_REFRESH_FAILED,
} from "../../constants/actionTypes";

import { getLoggedInUser } from "../../helpers/authUtils";

const INIT_STATE = {
  user: getLoggedInUser(),
  isAuthenticated: false,
  data_loaded: false,
};

const AuthUtils = (state = INIT_STATE, action) => {
  switch (action.type) {
    case TOKEN_REFRESH:
      return { ...state, data_loaded: false };
    case TOKEN_REFRESH_RECEIVED:
      console.log("TOKEN_REFRESH_RECEIVED");
      return { ...state, isAuthenticated: true, data_loaded: true };
    case TOKEN_REFRESH_FAILED:
      return { ...state, data_loaded: true };
    default:
      return { ...state };
  }
};

export default AuthUtils;
