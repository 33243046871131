// @flow
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  LOGIN_USER,
  LOGOUT_USER,
  REGISTER_USER,
  VERIFY_USER,
  FORGET_PASSWORD,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
} from "../../constants/actionTypes";

import {
  loginUserSuccess,
  loginUserFailed,
  registerUserSuccess,
  registerUserFailed,
  verifyAccountSuccess,
  verifyAccountFailed,
  forgetPasswordSuccess,
  forgetPasswordFailed,
  resetPasswordSuccess,
  resetPasswordFailed,
  changePasswordSuccess,
  changePasswordFailed,
} from "./actions";
var authUtils = require("../../helpers/authUtils");
var config = require("./../../config");
var Utils = require("./../../helpers/Utils");
/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */
const fetchJSON = (url, options = {}) => {
  options.headers["Authorization"] = Utils.encString(options).hash;
  return fetch(config.apibase + url, options)
    .then((response) => {
      // if (response.status !== 200) {
      //   throw response.json();
      // }
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { username, password } }) {
  const options = {
    body: JSON.stringify({ username, password }),
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };
  try {
    const responseData = yield call(fetchJSON, "/users/authenticate", options);
    //console.log(responseData);
    if (responseData.code === 200) {
      const response = responseData.data ? responseData.data : responseData;
      var user = response.user ? response.user : {};
      authUtils.setSession(user);
      yield put(loginUserSuccess(response));
    } else {
      var message =
        typeof responseData.message == "object" && responseData.message.message
          ? responseData.message.message
          : responseData.message;

      yield put(loginUserFailed({ message: message }));
    }
  } catch (error) {
    console.log("error error", error);
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(loginUserFailed(message));
    authUtils.setSession(null);
  }
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout({ payload: { username, history } }) {
  const options = {
    body: JSON.stringify({ username }),
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };
  try {
    const response = yield call(fetchJSON, "/users/logout", options);
    console.log("responseresponse", response);
    authUtils.setSession(null);
    yield call(() => {
      history.push("/login");
    });
  } catch (err) {
    console.log("Logout Error", err.message || JSON.stringify(err));
  }
}

/**
 * Register the user
 */
function* register({ payload: { email, password } }) {
  const options = {
    body: JSON.stringify({ email, password }),
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };

  try {
    const responseData = yield call(fetchJSON, "/users/register", options);
    if (responseData.code === 200) {
      const response = responseData.data ? responseData.data : responseData;
      console.log("response", response);
      yield put(registerUserSuccess(response));
    } else {
      var message =
        typeof responseData.message == "object" && responseData.message.message
          ? responseData.message.message
          : responseData.message;
      yield put(registerUserFailed({ message: message }));
    }
  } catch (error) {
    console.warn("Registration Error", error);
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(registerUserFailed(message));
  }
}

/**
 * Verify the user account
 */
function* verifyUser({ payload: { email, code } }) {
  const options = {
    body: JSON.stringify({ email, code }),
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };

  try {
    const responseData = yield call(fetchJSON, "/users/verify", options);

    if (responseData.code === 200) {
      const response = responseData.data ? responseData.data : responseData;
      yield put(verifyAccountSuccess(response));
    } else {
      var message =
        typeof responseData.message == "object" && responseData.message.message
          ? responseData.message.message
          : responseData.message;
      yield put(verifyAccountFailed({ message: message }));
    }
  } catch (error) {
    console.warn("Verification Error", error);
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(verifyAccountFailed(message));
  }
}

/**
 * Reset Password of the user account
 */
function* resetPassword({ payload: { username, code, password } }) {
  const options = {
    body: JSON.stringify({ username, code, password }),
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };

  try {
    const responseData = yield call(
      fetchJSON,
      "/users/reset-password",
      options
    );

    if (responseData.code === 200) {
      const response = responseData.data ? responseData.data : responseData;
      yield put(resetPasswordSuccess(response));
    } else {
      var message =
        typeof responseData.message == "object" && responseData.message.message
          ? responseData.message.message
          : responseData.message;
      yield put(resetPasswordFailed({ message: message }));
    }
  } catch (error) {
    console.warn("Reset Password Error", error);
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(resetPasswordFailed(message));
  }
}

/**
 * forget password
 */
function* forgotPassword({ payload: { username } }) {
  const options = {
    body: JSON.stringify({ username }),
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };
  try {
    const responseData = yield call(
      fetchJSON,
      "/users/forgot-password",
      options
    );
    if (responseData.code === 200) {
      const response = responseData.data ? responseData.data : responseData;
      yield put(forgetPasswordSuccess(response.message));
    } else {
      var message =
        typeof responseData.message == "object" && responseData.message.message
          ? responseData.message.message
          : responseData.message;
      yield put(forgetPasswordFailed({ message: message }));
    }
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(forgetPasswordFailed(message));
  }
}

/**
 * change password
 */
function* changePassword({ payload: { username, oldpassword, newpassword } }) {
  const options = {
    body: JSON.stringify({ username, oldpassword, newpassword }),
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };
  console.log("responseData", options.body);
  try {
    const responseData = yield call(
      fetchJSON,
      "/users/change-password",
      options
    );
    console.log("responseData", responseData);
    if (responseData.code === 200) {
      const response = responseData.data ? responseData.data : responseData;
      yield put(changePasswordSuccess(response.message));
    } else {
      var message =
        typeof responseData.message == "object" && responseData.message.message
          ? responseData.message.message
          : responseData.message;
      yield put(changePasswordFailed({ message: message }));
    }
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(changePasswordFailed(message));
  }
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, register);
}

export function* watchVerifyUser() {
  yield takeEvery(VERIFY_USER, verifyUser);
}

export function* watchForgetPassword() {
  yield takeEvery(FORGET_PASSWORD, forgotPassword);
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

export function* watchChangePassword() {
  yield takeEvery(CHANGE_PASSWORD, changePassword);
}

function* authSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgetPassword),
    fork(watchVerifyUser),
    fork(watchResetPassword),
    fork(watchChangePassword),
  ]);
}

export default authSaga;
