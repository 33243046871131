import React from "react";
import Joi from "joi-browser";
import Title from "./../../components/Title";
import dataservice from "../../helpers/dataservice";
import {
    Form,
    FormGroup,
    Input,
    Label,
    FormFeedback,
    Row,
    Col,
    Button,
  } from "reactstrap";

class ReferralMap extends React.Component{
 
    state={
        data:{},
        errors:{},
        send:false,
    }
    schema = {
        user_id: Joi.string().max(255).required(),
        referred_by_user_id: Joi.string().max(255).required(""),
      };
      validateProperty = (event) => {
        const { name, value } = event.target;
        const obj = { [name]: value };
        const subSchema = { [name]: this.schema[name] };
        const result = Joi.validate(obj, subSchema);
        const { error } = result;
        return error ? error.details[0].message : null;
      };

      handleChange = (event) => {
        const { name, value } = event.target;
        let errorData = { ...this.state.errors };
        const errorMessage = this.validateProperty(event);
        if (errorMessage) {
          errorData[name] = errorMessage;
        } else {
          delete errorData[name];
        }
        let data = { ...this.state.data };
        data[name] = value;
        this.setState({
            data: data,
          errors: errorData,
        });
      };
      validateForm = () => {
        const result = Joi.validate(this.state.data, this.schema, {
          abortEarly: false,
        });
        const { error } = result;
        if (!error) {
          return null;
        } else {
          const errorData = {};
          for (let item of error.details) {
            const name = item.path[0];
            const message = item.message;
            errorData[name] = message;
          }
          this.setState({
            errors: errorData,
          });
          return errorData;
        }
      };
    
      handleSubmit = async (event) => {
        event.preventDefault();
        if (this.validateForm()) {
          alert("Check For Form Errors");
          return;
        } else {
       
          try {
            const send_data = { ...this.state.data };
           let response= await dataservice.fetchJSON("/user/map-referral", {
              body: send_data,
              method: "POST",
              headers: { "Content-Type": "application/json" },
            });
            let map = response.data.map;
            if(map){
                
                alert("successfully mapped")
            }
            else
            {
                alert("not possible to map")
            }
            window.location.reload(false);
          } catch (ex) {
            console.log(ex)
            alert(`Something Went Wrong\n${ex.message}`);
          }
        
        }
      };

    render(){
        const { data, errors, send } = this.state;
        return ( <div>
            <Title title="Referral Map"></Title>
            <Row>
              <Col>
                <Form
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  onSubmit={this.handleSubmit}
                >
                  <FormGroup>
                    <Label for="user_id">User Id* </Label>
                    <Input
                      type="text"
                      name="user_id"
                      id="user_id"
                      placeholder="User Id "
                      value={data.user_id}
                      onChange={this.handleChange}
                      invalid={!!errors.user_id}
                    ></Input>
                    <FormFeedback>{errors.user_id}</FormFeedback>
                  </FormGroup>
    
                  <FormGroup>
                    <Label for="referred_by_user_id">Referred By UserId* </Label>
                    <Input
                      type="text"
                      name="referred_by_user_id"
                      id="referred_by_user_id"
                      placeholder="Referred By UserID"
                      value={data.referred_by_user_id}
                      onChange={this.handleChange}
                      invalid={!!errors.referred_by_user_id}
                    ></Input>
                    <FormFeedback>{errors.referred_by_user_id}</FormFeedback>
                  </FormGroup>
                  <Button disabled={send} color="success">
                    Submit
                  </Button>
                </Form>
              </Col>
              <Col></Col>
            </Row>
          </div>)
    }
}
export default ReferralMap