import React, { Component, Suspense } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { connect } from "react-redux";
import Loadable from "react-loadable";

import { routes } from "./routes";

// setup fake backend
// import { configureAuthBackend } from "./helpers";
import { isUserAuthenticated } from "./helpers/authUtils";
import { getTokenRefresh } from "./redux/authutils/actions";

// Themes
import "./assets/scss/DefaultTheme.scss";
// react-bootstrap-table-next css
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

// Lazy loading and code splitting -
// Derieved idea from https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const loading = () => <div></div>;

// All layouts/containers
const NonAuthLayout = Loadable({
  loader: () => import("./components/NonAuthLayout"),
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
  loading,
});

const AuthLayout = Loadable({
  loader: () => import("./components/AuthLayout"),
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
  loading,
});

// configure auth backend
// configureAuthBackend();

/**
 * Exports the component with layout wrapped to it
 * @param {} WrappedComponent
 */
const withLayout = (WrappedComponent) => {
  const HOC = class extends Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return connect()(HOC);
};

/**
 * Main app component
 */
class App extends Component {
  componentDidMount() {
    this.props.getTokenRefresh();
    //set a timer for interval call this function everytime
    //delete that timer on component Unmount
  }
  /**
   * Returns the layout component based on different properties
   * @param {*} props
   */
  getLayout = () => {
    return isUserAuthenticated() ? AuthLayout : NonAuthLayout;
  };

  render() {
    if (!this.props.data_loaded) {
      return <div />;
    }

    return (
      // rendering the router with layout
      <BrowserRouter>
        <React.Fragment>
          <Switch>
            {routes.map((route, index) => {
              return (
                <route.route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  roles={route.roles}
                  component={withLayout((props) => {
                    const Layout = this.getLayout();

                    return (
                      <Suspense fallback={loading()}>
                        <Layout {...props}>
                          <route.component {...props} />
                        </Layout>
                      </Suspense>
                    );
                  })}
                />
              );
            })}
          </Switch>
        </React.Fragment>
      </BrowserRouter>
    );
  }
}

const mapDispatchToProps = {
  getTokenRefresh,
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.AuthUtils.isAuthenticated,
    data_loaded: state.AuthUtils.data_loaded,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
