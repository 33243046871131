import config from "../config";
import Utils from "./Utils";
const fetchJSON = (
  url,
  options = {
    body: {},
    method: "POST",
    headers: { "Content-Type": "application/json" },
  }
) => {
  const authData = Utils.authData();

  if (authData.tokens) {
    options.body.tokens = JSON.stringify(authData.tokens);
    options.body.username = JSON.stringify(authData.username);
    options.body = JSON.stringify(options.body);
  }

  options.headers["Authorization"] = Utils.encString(options).hash;

  return fetch(config.apibase + url, options)
    .then(async (response) => {
      if (response.status !== 200) {
        const res = await response.json();
        throw res;
      }

      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      throw error;
    });
};

const fetchStory = (
  url,
  options = {
    body: {},
    method: "POST",
    headers: { "Content-Type": "application/json" },
  }
) => {
  const authData = Utils.authData();

  if (authData.tokens) {
    options.body.tokens = JSON.stringify(authData.tokens);
    options.body.username = JSON.stringify(authData.username);
    options.body = JSON.stringify(options.body);
  }

  options.headers["Authorization"] = Utils.encString(options).hash;

  return fetch(config.apibasestory + url, options)
    .then(async (response) => {
      if (response.status !== 200) {
        const res = await response.json();
        throw res;
      }

      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      throw error;
    });
};

export default { fetchJSON, fetchStory };
