// @flow
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { TOKEN_REFRESH } from "../../constants/actionTypes";

import {} from "./actions";
var config = require("../../config");
// var querystring = require("querystring");
var Utils = require("../../helpers/Utils");
var authUtils = require("../../helpers/authUtils");

/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */
const fetchJSON = (url, options = {}) => {
  const authData = Utils.authDataRefresh();
  if (authData.tokens) {
    options.body.tokens = JSON.stringify(authData.tokens);
    options.body.username = JSON.stringify(authData.username);
    options.body = JSON.stringify(options.body);
  } else {
    throw new Error("User is logged out");
  }
  options.headers["Authorization"] = Utils.encString(options).hash;
  return fetch(config.apibase + url, options)
    .then((response) => {
      if (!response.status === 200) {
        throw response.json();
      }
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * get daily revenue
 * @param {*} payload - merchantId
 */
function* tokenRefresh({ payload }) {
  const options = {
    body: {},
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };
  try {
    if (authUtils.getLoggedInUser()) {
      const response = yield call(fetchJSON, "/userdata/refresh?", options);
      if (response.code && response.code === 200) {
        if (response.new_tokens && response.new_tokens.refresh_token) {
          let user = authUtils.getLoggedInUser();
          const tokens = response.new_tokens ? response.new_tokens : {};
          user.id_token = tokens.id_token;
          user.access_token = tokens.access_token;
          user.refresh_token = tokens.refresh_token;
          user.expiry = tokens.expiry;
          console.log("refresh response", user);
          authUtils.setSession(user);
        }
        yield put({ type: "TOKEN_REFRESH_RECEIVED", payload: {} });
      } else {
        authUtils.setSession(null);
        console.log("error", "TOKEN_REFRESH_FAILED");
        yield put({ type: "TOKEN_REFRESH_FAILED", payload: {} });
      }
    } else {
      console.log("error", "TOKEN_REFRESH_FAILED 1");
      yield put({ type: "TOKEN_REFRESH_FAILED", payload: {} });
    }
  } catch (error) {
    console.log("error", error);
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid data";
        break;
      default:
        message = error;
    }
    yield put({ type: "TOKEN_REFRESH_FAILED", message });
  }
}

export function* watchTokenRefresh() {
  yield takeEvery(TOKEN_REFRESH, tokenRefresh);
}

function* authUtilsSaga() {
  yield all([fork(watchTokenRefresh)]);
}

export default authUtilsSaga;
